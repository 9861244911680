import PropTypes from 'prop-types'
import React from 'react'

import loadable from '@loadable/component'
import { useWindowSize } from '../helpers/useWindowSize'

const GalleryMobile = loadable(() => import('./Gallery/Mobile'))
const GalleryDesktop = loadable(() => import('./Gallery/Desktop'))

const PageBuilderGallery = ({ block, raw, index, booking }, props) => {
  const windowSize = useWindowSize()

  return (
    <section>
      {windowSize.width <= 768 && (
        <GalleryMobile
          bgImg={block.bgImg}
          gallery={block.gallery}
          heading={block.heading}
          booking={booking}
        />
      )}
      {windowSize.width > 768 && (
        <GalleryDesktop
          bgImg={block.bgImg}
          gallery={block.gallery}
          heading={block.heading}
          booking={booking}
        />
      )}
    </section>
  )
}

PageBuilderGallery.propTypes = {
  block: PropTypes.object,
  raw: PropTypes.object,
  index: PropTypes.number,
}

PageBuilderGallery.defaultProps = {
  block: {},
  raw: {},
  index: 0,
}

export default PageBuilderGallery
